

























import { Image } from "@/types/components/posts";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class PostListItem extends Vue {
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly description!: string;
    @Prop({ type: String, required: true }) readonly link!: string;
    @Prop({ type: Array, required: true }) readonly images!: Image[];
}
