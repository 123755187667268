








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Marquee extends Vue {
    @Prop({ type: String, required: true }) readonly text!: string;

    mounted() {
        this.adjustAnimationSpeed();
    }

    private adjustAnimationSpeed() {
        let marquee = this.$refs.marquee as HTMLElement | undefined;
        if (marquee) {
            let len = marquee.textContent ? marquee.textContent.length : 0;
            let nodes = marquee.children;
            for (let i = 0; i < nodes.length; i++) {
                (nodes[i] as HTMLElement).style.animationDuration =
                    len / 5 + "s";
            }
        }
    }
}
