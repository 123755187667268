













import { Component, Vue, Prop } from "vue-property-decorator";
import PostListItem from "./PostListItem.vue";
import { Post } from "@/types/components/posts";

@Component({
    components: {
        PostListItem
    }
})
export default class PostList extends Vue {
    @Prop({ type: Array, required: true }) readonly posts!: Post[];
}
